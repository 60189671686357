import React from 'react';
import Masonry from 'react-masonry-css'
import './App.css';
import { projects } from './projects';

function App() {
  const allItems = projects.map(x => {
    return <Item key={x.title} title={x.title} caption={x.caption} url={x.url} image={x.image} />
  })
  const breakpointColumnsObj = {
  default: 5,
  1100: 3,
  700: 2,
  500: 1
};
  return (
    <div id="container">
      <Header />
      <Links />
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column">
        { allItems }
      </Masonry>
      <Footer />
    </div>
  );
}

export default App;

function Header(props){
  return(
      <div className="mark">
        <a href="https://twitter.com/intent/follow?user_id=9683992" className="twitter-follow-web-intent"><img src="/images/k3nd477.svg" alt="@k3nd477" /></a>
      </div>
  )
}

function Footer(props){
  return(
      <div className="footer">
        Copyright &copy; {(new Date().getFullYear())} Kendall Guillemette
      </div>
  )
}



function Links(props){
  return(
    <div className="links-out">
      <ul>
        <li><a href="https://www.threads.net/@k3nd477">Threads</a></li>
        <li><a href="https://www.instagram.com/k3nd477/" target="_blank" rel="noopener noreferrer">Instagram</a></li>
        <li><a href="https://stolen-signs.fireside.fm/" target="_blank" rel="noopener noreferrer">Stolen Signs Podcast</a></li>
        <li><a href="https://groupproject.fireside.fm/" target="_blank" rel="noopener noreferrer">Group Project Podcast</a></li>
      </ul>
    </div>
  )
}

function Item(props) {
  return (
    <div className="item">
      <a href={props.url} data-label={props.title} target="_blank" rel="noopener noreferrer">
        <img src={props.image} alt={props.title} />
        <div className="content">
          <h4>{props.title}</h4>
          <p>{props.caption}</p>
        </div>
      </a>
    </div>
  )
}
