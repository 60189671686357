export const projects = [
  {
    "title": "Bookshop",
    "caption": "My Top 10 Books available online from independent bookshops.",
    "image": "/images/2020-09-04-top-10-books.png",
    "url": "https://bookshop.org/lists/top-10-books-c0614ce2-e93d-4eb8-80da-22205bfa268d"
  },
  {
    "title": "Home Team Report",
    "caption": "Sports news aggregation application",
    "image": "images/htr.png",
    "url": "https://hometeamreport.com"
  },
  {
    "title": "Gerbera Daisy t-shirt",
    "caption": "available on Cotton Bureau",
    "image": "https://cottonbureau.com/mockup?pid=79715&detail=1&submissionFallback=1&hash=6QZw&w=512",
    "url": "https://cottonbureau.com/p/4ZPUJK/shirt/gerbera-daisies"
  },
  {
    "title": "Man at Midlife",
    "caption": "Substack newsletter",
    "image": "/images/man-at-midlife.png",
    "url": "https://manatmidlife.substack.com/"
  },
  {
    "title": "Grimacing Bird t-shirt",
    "caption": "available on Cotton Bureau",
    "image": "https://cottonbureau.com/mockup?vid=14174252&hash=3bwN&w=2048",
    "url": "https://cottonbureau.com/p/BWKPCY/shirt/grimacing-bird"
  },

  {
    "title": "Bo Jackson",
    "caption": "Savage Baseball Birthdays Project",
    "image": "/images/2016-11-30-bo-jackson-portfolio.jpg",
    "url": "https://www.instagram.com/p/BNcOA3dBJYn/"
  },
  {
    "title": "Beating Color",
    "caption": "iPhone wallpaper download",
    "image": "/images/beating-color-iphone-6s.jpg",
    "url": "/images/beating-color-iphone-6s.jpg"
  },
  {
    "title": "Kyle Hendricks",
    "caption": "Drawing pad experiment",
    "image": "/images/kyle-hendricks.jpg",
    "url": "https://www.instagram.com/p/BMHxogQhwcj/"
  },

  // {
  //   "title": "Consensus Top Baseball Prospects",
  //   "caption": "Custom application compiling various baseball top prospect lists",
  //   "image": "/images/top-prospects.jpg",
  //   "url": "http://fantasybaseball.co/prospects"
  // },
  {
    "title": "Heather Christo",
    "caption": "Custom hand lettering",
    "image": "/images/heather-christo-lettering.jpg",
    "url": "http://heatherchristo.com"
  },
  {
    "title": "My Oh My! t-shirt",
    "caption": "available on Cotton Bureau",
    "image": "https://cottonbureau.com/mockup?vid=1962393&hash=FcT5&w=2048",
    "url": "https://cottonbureau.com/products/my-oh-my#/1962393/"
  }
]


/*

 	<div class="item">
 		<a href="http://heatherchristo.com" data-label="Heather Christo" target="_blank">
 			<img src="/_/images/heather-christo-lettering.jpg">
 			<div class="content">
 				<h4>Heather Christo</h4>
 				<p>Custom hand lettering</p>
 			</div>
 		</a>
 	</div>
 	<div class="item">
 		<a href="http://www.amazon.com/gp/product/B017S1O9N2?*Version*=1&*entries*=0" data-label="Keep the line moving" target="_blank">
 			<img src="/_/images/keep-line-moving.jpg">
 			<div class="content">
 				<h4>Keep the line moving</h4>
 				<p>available at Amazon</p>
 			</div>
 		</a>
 	</div>
 	<div class="item">
 		<a href="https://www.instagram.com/p/8SdUnEK3VJ/" data-label="Stay Active" target="_blank">
 			<img src="https://instagram.fsnc1-1.fna.fbcdn.net/hphotos-xfp1/t51.2885-15/s480x480/e35/11934694_1641599439451788_563288840_n.jpg">
 			<div class="content">
 				<h4>Stay Active</h4>
 			</div>
 		</a>
 	</div>
 	<div class="item">
 		<a href="https://instagram.com/p/7dW4IOq3Qs/" data-label="Parts Unknown" target="_blank">
 			<img src="/_/images/parts-unknown-ig.jpg">
 			<div class="content">
 				<h4>Parts Unknown</h4>
 			</div>
 		</a>
 	</div>
 	<div class="item">
 		<a href="http://drbl.in/pRyf" target="_blank" data-label="Cardinal">
 			<img src="/_/images/dribbble-cardinal.jpg">
 			<div class="content">
 				<h4>Cardinal</h4>
 			</div>
 		</a>
 	</div>
 	<div class="item">
 		<a href="http://www.amazon.com/gp/product/B01A9E6O2O" data-label="My Oh My" target="_blank">
 			<img src="/_/images/my-oh-my.jpg">
 			<div class="content">
 				<h4>My Oh My! t-shirt</h4>
 				<p>available at Amazon</p>
 			</div>
 		</a>
 	</div>

 	<div class="item">
 		<a href="https://instagram.com/p/5Ksgdtq3SO/" data-label="Blackletter K" target="_blank">
 			<img src="/_/images/instagram-k.jpg">
 			<div class="content">
 				<h4>Blackletter K</h4>
 			</div>
 		</a>
 	</div>
 	<div class="item">
 		<a href="http://drbl.in/pRye" data-label="Blackletter P" target="_blank">
 			<img src="/_/images/dribbble-p.jpg">
 			<div class="content">
 				<h4>Blackletter P</h4>
 			</div>
 		</a>
 	</div>
 	<div class="item">
 		<a href="https://medium.com/@K3ND477/letting-go-of-fear-is-exhausting-3a99b5027e45#.1at7ewbfv" data-label="Fear" target="_blank">
 			<img src="/_/images/ktg-fear-dribbble2.jpg">
 			<div class="content">
 				<h4>Fear</h4>
 				<p>from Grounded Magazine</p>
 			</div>
 		</a>
 	</div>
*/
